var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashbaord-analytics",
      class: { "pt-3": _vm.isMobile },
      attrs: { id: "dashboard-analytics" },
    },
    [
      _c("div", [
        _c("div", { staticClass: "md:mt-0 ml-0" }, [
          _c(
            "div",
            { staticClass: "mt-5 mb-5 dashboard-toolbar" },
            [
              _vm.HAS_VEGA_REPORT_ACCESS
                ? _c("vega-reports", {
                    staticClass: "mr-4",
                    attrs: { vegaWidgetList: _vm.vegaWidgetList },
                  })
                : _vm._e(),
              _vm.HAS_CALL_CENTER_REPORT_ACCESS
                ? _c("call-center-csv-reports", {
                    staticClass: "mr-4",
                    attrs: { dateRange: _vm.dateRange },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "dashboard-toolbar-filters",
                  class: _vm.isLoading ? "disabled" : "",
                },
                [
                  _c("filters", {
                    key: _vm.filterKey,
                    attrs: {
                      dialogList: _vm.widgetList,
                      campaignList: _vm.campaignList,
                      localeData: _vm.localeData,
                      selectedDialogs: _vm.selectedDialogs,
                      selectedCampaigns: _vm.selectedCampaigns,
                      isCampaignFilter: true,
                    },
                    on: {
                      campaignChanged: _vm.handleCampaignChange,
                      dialogChanged: _vm.handleDialogChange,
                      dateRangeChanged: _vm.handleDateRangeChange,
                      accountChanged: _vm.handleAccountChange,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "dashboard-toolbar-controls" }, [
                _c(
                  "div",
                  { staticClass: "dashboard-toolbar-controls-refresh" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "dashboard-toolbar-controls-refresh-btn",
                        class: _vm.disableRefreshBtn ? "disabled" : "",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.refreshData.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "dashboard-toolbar-controls-refresh-btn-wording",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("views.home.refreshData")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "md:mt-0 ml-0",
            staticStyle: { "margin-bottom": "20px" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "pt-5 analytics-container--right-grow1 vega-analytics-container",
              },
              [
                _c(
                  "div",
                  { staticClass: "ml-0 margin-rt" },
                  [
                    _c("VegaAnalyticsSummary", {
                      attrs: {
                        series: _vm.vegaVisitorsCountPerDayChart,
                        seriesShowCountPerDay: _vm.showCountPerDay,
                        weekdays: _vm.localeData.daysOfWeek,
                        isLoading:
                          _vm.isVegaVisitorsCountPerDayDataLoading ||
                          _vm.isTotalVisitorsCountLoading ||
                          _vm.isDialogShowsPerDayOfWeekLoading,
                      },
                      on: { activeViewChanged: _vm.changeActiveView },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "20px" } },
          [
            _c("analytics-container", {
              attrs: {
                isMobile: _vm.isMobile,
                isTotalVisitorsCountLoading: _vm.isTotalVisitorsCountLoading,
                isRequestsLoading: _vm.isRequestsLoading,
                isSessionsLoading: _vm.isSessionsLoading,
                isCampaignRequestsLoading: _vm.isCampaignRequestsLoading,
                isCampaignSessionsLoading: _vm.isCampaignSessionsLoading,
                topVisitors: _vm.topVisitors,
                isVisitorsLocationLoading: _vm.isVisitorsLocationLoading,
                agents: _vm.agentsData,
                isAgentsLoading: _vm.isAgentsLoading,
                routeClicks: _vm.routeClicks,
                isRouteClicksLoading: _vm.isRouteClicksLoading,
                deviceData: _vm.deviceData,
                isDeviceLoading: _vm.isDeviceLoading,
                browserData: _vm.browserData,
                browserDataTableConfig: _vm.browserDataTableCongig,
                isBrowserDataLoading: _vm.isBrowserDataLoading,
                topOrigins: _vm.topOrigins,
                isOriginDataLoading: _vm.isOriginDataLoading,
                topOriginsTableConfig: _vm.topOriginsTableCongig,
                topReferers: _vm.topReferers,
                isRefererDataLoading: _vm.isRefererDataLoading,
                topReferersTableConfig: _vm.topReferersTableCongig,
                sessionDuration: _vm.sessionDuration,
                isSessionDurationLoading: _vm.isSessionDurationLoading,
                shownContactForm: _vm.contactFormShownCount,
                filledContactForm: _vm.contactFormFilledCount,
                bookedContactForm: _vm.contactFormBookedAppointmentCount,
                isContactFormLoading: _vm.isContactFormLoading,
                endScreenFeedbackData: _vm.endScreenFeedbackData,
                isEndScreenFeedbackDataLoading:
                  _vm.isEndScreenFeedbackDataLoading,
                visitorsRating: _vm.visitorsRating,
                HAS_ADVANCED_DASHBOARD_ACCESS:
                  _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                isVegaConversationStatusDataLoading:
                  _vm.isVegaConversationStatusDataLoading,
                isVegaFilledContactFormStatusDataLoading:
                  _vm.isVegaFilledContactFormStatusDataLoading,
                isVegaAgentConversationRatingDataLoading:
                  _vm.isVegaAgentConversationRatingDataLoading,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ml-0" },
          [
            _c("vega-analytics", {
              attrs: {
                isMobile: _vm.isMobile,
                isVegaConversationStatusDataLoading:
                  _vm.isVegaConversationStatusDataLoading,
                isVegaFilledContactFormStatusDataLoading:
                  _vm.isVegaFilledContactFormStatusDataLoading,
                isVegaAgentConversationRatingDataLoading:
                  _vm.isVegaAgentConversationRatingDataLoading,
                isVegaVisitorAnalyticsDataLoading:
                  _vm.isVegaVisitorAnalyticsDataLoading,
                isVegaScoresAnalyticsDataLoading:
                  _vm.isVegaScoresAnalyticsDataLoading,
                isVegaCampaignsDataLoading: _vm.isVegaCampaignsDataLoading,
                isVegaVisitorScoreFlowDataLoading:
                  _vm.isVegaVisitorScoreFlowDataLoading,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }